/* Import your CSS files here */
/* @import "bootstrap/dist/css/bootstrap.css"; */
@import "noty/lib/noty.css";
@import "noty/lib/themes/light.css";
@import "../assets/stylesheets/actiontext.css";
/* Add your custom styles below */ 

/* ... existing comments ... */

trix-editor.form-control {
  height: auto;
}

.custom-select {
  background: none !important;
}

.starter-wrapper {
  padding-top: 4rem;
}

* {
  box-sizing: border-box;
}

body {
  font-family: helvetica, arial, sans-serif;
  margin: 25px;
  color: #000000; /* Replace $black variable */
}

.table {
  color: #006400; /* Replace $dark-green variable with an appropriate hex color */
}

input {
  display: block;
  margin-bottom: 10px;
  padding: 5px;
  width: 100%;
  border: 1px solid lightgrey;
  border-radius: 3px;
  font-size: 16px;
}

li {
  margin-bottom: 5px;
}

footer {
  margin-top: 50px;
  padding-top: 25px;
  border-top: 1px solid lightgrey;
}

footer > a {
  color: #BBBBBB;
}

.nicejob {
  text-decoration: line-through;
}

/* hill chart styles */
.line {
  fill: none;
  stroke: steelblue;
  stroke-width: 1;
}

circle {
  stroke: #fff;
  stroke-width: 2;
}

.middle {
  fill: none;
  stroke: lightgrey;
  stroke-width: 1;
  shape-rendering: crispedges;
  stroke-dasharray: 5, 5;
}

.text {
  text-transform: uppercase;
  fill: #bbb;
  font-weight: bold;
  text-anchor: middle;
}

.group {
  cursor: pointer;
}

.group.draggable {
  cursor: move;
}

.group > text {
  text-anchor: start;
}

